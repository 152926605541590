import Img from 'gatsby-image';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { DefaultLayout } from '../../components/layout';
import { CaseBlock, Stage } from '../../components/molecules';

const schoolofaiQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "schoolofai" } }) {
      edges {
        node {
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
      }
    }
    allImageSharp(filter: { fluid: { originalName: { regex: "/(bot|school_meetup|meetup)/" } } }) {
      edges {
        node {
          id
          fluid(maxWidth: 800, quality: 92) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

const SchooloaiCase = () => (
  <StaticQuery
    query={schoolofaiQuery}
    render={({ allStagesJson, allImageSharp }) => {
      const stageData = allStagesJson.edges[0].node;
      const classImg = allImageSharp.edges[3].node.fluid;
      const meetupImg = allImageSharp.edges[1].node.fluid;

      return (
        <DefaultLayout>
          <Stage
            modifiers={['gradient', 'case']}
            image={{
              fluid: stageData.imageSrc.childImageSharp.fluid,
              alt: stageData.imageAlt,
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: stageData.title }} />}
          >
            <div>
              {stageData.contentBlocks.map((block) => (
                <p key={block.id} dangerouslySetInnerHTML={{ __html: block.value }} />
              ))}
            </div>
          </Stage>

          <CaseBlock
            title="KI Unterricht"
            subtitle="Kostenslos für alle!"
            graphic={<Img fluid={classImg} alt="School of AI Presentation" />}
          >
            <p>
              Die Mission der School of AI ist es, einen erstklassigen und weltweiten KI Unterricht zu gewährleisten. Die
              Türen stehen für alle, die gerne lernen möchten, offen. Wir sind eine Lerngemeinschaft, die sich über fast
              jedes Land erstreckt und sich der Aufgabe widmet, unseren Schülern beizubringen, wie sie mithilfe der
              KI-Technologie einen positiven Einfluss auf die Welt erzielen können, sei es durch ein Angestelltenverhältnis
              oder durch Unternehmertum.
            </p>
          </CaseBlock>

          <CaseBlock
            title="Unsere Treffen"
            subtitle="Finden Sie uns auf Meetup.com"
            graphic={<Img fluid={meetupImg} alt="Meetup Logo" href="https://www.meetup.com/Bern-School-Of-Ai/" />}
            isReverse
          >
            <p>
              {/* added this file to .eslintignore because of the weird space problems with the link */}
              Wir organisieren unsere Treffen über die Plattform Meetup.com.{' '}
              <a href="https://www.meetup.com/Bern-School-Of-Ai/">Hier</a> finden Sie unsere nächsten Treffen.
            </p>
          </CaseBlock>
        </DefaultLayout>
      );
    }}
  />
);

export default SchooloaiCase;
